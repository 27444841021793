/* prevent unicode char notation replacement */
@function uchar($character-code) {
  @if function-exists("selector-append") {
    @return unquote('"\\#{$character-code}"');
  }
  @if "\\#{'x'}" == "\\x" {
    @return str-slice("\x", 1, 1) + $character-code;
  } @else {
    @return #{'"\\'}#{$character-code + '"'};
  }
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.custom-container {
  margin-left: auto;
  margin-right: auto;
}

.custom-container {
  @media (min-width: 768px) {
    width: 750px;
  }
}

.custom-container {
  @media (min-width: 992px) {
    width: 970px;
  }
}

.custom-container {
  @media (min-widths: 1200px) {
    width: 1170px;
  }
}

.content {
  margin: 82px auto 32px;
  padding: 0 16px;
  align-items: center;
}

.panel,
.subpanel {
  border-radius: 5px;
  margin: 10px 0;
  padding: 20px;
}

.panel {
  background-color: lightgrey;
}

.subpanel {
  background-color: white;
}

.subpanel-row {
  display: flex;
}

.subpanel-column {
  width: 50%;
}

.column-title {
  width: 50%;
  display: inline-block;
}

// override material

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-floating-label,
::placeholder {
  color: #068a9b !important;
}

.mat-mdc-form-field-infix,
.mat-mdc-form-field-flex,
.mat-mdc-select-panel,
.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}

.mat-mdc-select-arrow svg {
  display: none;
}

.mat-mdc-select-trigger::after {
  content: uchar(25be); /* = "\25be" = "▾" */
  transform: scale(2, 1);
}

.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-tab--active,
.mdc-tab--active .mdc-tab__text-label {
  color: #ea5901 !important;
}

.mdc-tab-indicator__content--underline {
  --mdc-tab-indicator-active-indicator-color: #ea5901 !important;
  --mat-tab-header-disabled-ripple-color: #ea5901 !important;
  --mat-tab-header-pagination-icon-color: #ea5901 !important;
  --mat-tab-header-inactive-label-text-color: #ea5901 !important;
  --mat-tab-header-active-label-text-color: #ea5901 !important;
  --mat-tab-header-active-ripple-color: #ea5901 !important;
  --mat-tab-header-inactive-ripple-color: #ea5901 !important;
  --mat-tab-header-inactive-focus-label-text-color: #ea5901 !important;
  --mat-tab-header-inactive-hover-label-text-color: #ea5901 !important;
  --mat-tab-header-active-focus-label-text-color: #ea5901 !important;
  --mat-tab-header-active-hover-label-text-color: #ea5901 !important;
  --mat-tab-header-active-focus-indicator-color: #ea5901 !important;
  --mat-tab-header-active-hover-indicator-color: #ea5901 !important;
}

.mat-calendar {
  background-color: white !important;
  font-size: small !important;
  font-weight: bold !important;
}

.mat-calendar-table-header {
  border-bottom: solid 1px lightgrey !important;
  font-size: small !important;
}

.mat-calendar-next-button:disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-calendar-body-selected {
  background-color: #ea5901 !important;
  color: white !important;
}

.mat-calendar-body-disabled {
  border-color: transparent !important;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-today {
  border: solid 1px lightgrey !important;
}

@media (max-width: 600px) {
  .mat-mdc-form-field-infix {
    width: 140px !important;
  }

  .mat-mdc-form-text-infix {
    width: 100% !important;
  }

  .mat-mdc-form-field-icon-suffix {
    display: none;
  }

  .mat-mdc-tab-links {
    display: block !important;
  }
}
