@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;
@use "sass:map";
@use "@material/button";

@include mat.core();
@include cdk.overlay();

// @import "./assets/fonts/SourceSansPro/SourceSansPro.css";
// @import "./assets/fonts/NGI-IGN_ICONS/NGI-IGN_ICONS.css";

/*
*
*
*   COLORS
*
*
*/

$ngi-orange: (
    main: #ea5901,
    lighter: #f9cdb3,
    darker: #e13e01,
    200: #ea5901,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white
        )
);

$ngi-dark-blue: (
    main: #2c3c56,
    lighter: #c0c5cc,
    darker: #1b273c,
    200: #2c3c56,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white
        )
);

$ngi-blue: (
    main: #006c9d,
    lighter: #b3d3e2,
    darker: rgb(0, 79, 129),
    200: #006c9d,
    // For slide toggle,
    contrast:
        (
            main: #006c9d,
            lighter: #29bbff,
            darker: rgb(0, 79, 129)
        )
);
$ngi-blue-green: (
    main: #068a9b,
    lighter: #b4dce1,
    darker: #036d7f,
    200: #068a9b,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white
        )
);

$ngi-green-blue: (
    main: #2fa298,
    lighter: #c1e3e0,
    darker: #1d867b,
    200: #2fa298,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white
        )
);
$ngi-black: (
    main: #181716,
    lighter: #bab9b9,
    darker: #0e0d0c,
    200: #181716,
    // For slide toggle,
    contrast:
        (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white
        )
);

$ngi-white: (
    main: #ffffff,
    lighter: #ffffff,
    darker: #f5f5f5,
    200: #ffffff,
    // For slide toggle,
    contrast:
        (
            main: rgba(black, 0.87),
            lighter: rgba(black, 0.87),
            darker: rgba(black, 0.87)
        )
);

$ngi-gray: (
    main: #b5b0af,
    lighter: #e9e7e7,
    darker: #9d9796,
    200: #b5b0af,
    // For slide toggle,
    contrast:
        (
            main: rgba(black, 0.87),
            lighter: rgba(black, 0.87),
            darker: rgba(black, 0.87)
        )
);

$ngi-lightgray: (
    main: #f0f0f0,
    lighter: #f9f9f9,
    darker: #ebebeb,
    200: #ebebeb,
    // For slide toggle,
    contrast:
        (
            main: rgba(black, 0.87),
            lighter: rgba(black, 0.87),
            darker: rgba(black, 0.87)
        )
);

/*
*
*
*   Foreground Elements
*
*
*/

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.64);
$dark-light-text: rgba(rgba(black, 0.87), 0.51);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

mat.$light-theme-foreground-palette: (
    base: black,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba(black, 0.38),
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: rgba(black, 0.38),
    elevation: black,
    secondary-text: $dark-accent-text,
    icon-text: #2c3c56,
    hint-text: rgba(black, 0.38),
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: rgba(black, 0.87),
    slider-min: rgba(black, 0.87),
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: rgba(black, 0.38),
    fab-text: mat.get-color-from-palette($ngi-dark-blue, main),
    orange-white: mat.get-color-from-palette($ngi-orange, main),
    link: mat.get-color-from-palette($ngi-blue, main),
    darkblue-white: mat.get-color-from-palette($ngi-dark-blue, main)
);

/*
*
*
*   Background Elements
*
*
*/

// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 3%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$light-bg-alpha-80: rgba(#fafafa, 0.8);
// $dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-tooltip: #2c3c56;
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);
$light-bg-lighter: rgba(#e0e0e0, 1);
$light-orange: rgba(#ffe4dc, 1);

// Background palette for light themes.
mat.$light-theme-background-palette: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-lighter-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: rgba(black, 0.12),
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
    map-controls: $light-bg-alpha-80,
    border: $light-bg-lighter,
    inactive: $light-bg-darker-5,
    active: $light-bg-darker-5,
    divider: $dark-bg-alpha-12,
    drawer-menu: $light-bg-lighter-5,
    // round-button:,,,,,,,
    fab: mat.get-color-from-palette($ngi-white, main),
    // tab-header: mat-color($ngi-blue, lighter),
    tab-header: #dfe6ef,
    sub-tab-header: #f5f5f5,
    // tab-header-hover: mat-color($ngi-dark-blue, lighter),
    tab-header-hover: $light-bg-darker-5,
    card-hover: rgb(177, 177, 177)
);

/*
*   LIGHT THEME
*/

$light-primary: mat.define-palette($ngi-black, main, lighter, darker);
$light-accent: mat.define-palette($ngi-orange, main, lighter, darker);
$light-warn: mat.define-palette(mat.$red-palette);
$ngi-light-theme: mat.define-light-theme(
    (
        color: (
            primary: $light-primary,
            accent: $light-accent,
            warn: $light-warn
        ),
        density: 0
    )
);

/*
*
*
*   MIXIN
*
*/

@mixin theme-color-apply($selected-theme) {
    // Parse the theme and create variables for each color in the pallete
    $primary: map-get($selected-theme, primary);
    $accent: map-get($selected-theme, accent);
    $warn: map-get($selected-theme, warn);
    //$text: map-get($selected-theme, text);
    $background: map-get($selected-theme, background);
    $foreground: map-get($selected-theme, foreground);
}

/*
*
*   END MIXIN
*
*/

.ngi-dark-blue-theme-light {
    @include mat.all-component-themes($ngi-light-theme);
    // @include angular-material-theme($ngi-light-theme);
    // @include navigation-header-theme($ngi-light-theme);
    // @include theme-color-apply($ngi-light-theme);
}

/* //////////////////////// */
